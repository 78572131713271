import {
  GET_PROPERTY_ERROR,
  GET_PROPERTY_INIT,
  GET_PROPERTY_SUCCESS,
  POST_PROPERTY_UPDATE_ERROR,
  POST_PROPERTY_UPDATE_INIT,
  POST_PROPERTY_UPDATE_SUCCESS,
} from "../constants/propertyConstant";

export const propertyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROPERTY_INIT:
      return { ...state, loading: true };
    case GET_PROPERTY_SUCCESS:
      return { ...state, propertListing: action.payload, loading: false };
    case GET_PROPERTY_ERROR:
      return { ...state, loading: false };

    // update the property
    case POST_PROPERTY_UPDATE_INIT:
      return { ...state, loading: false };
    case POST_PROPERTY_UPDATE_SUCCESS:
      return {
        ...state,
        propertyUpdatedStatus: true,
      };
    case POST_PROPERTY_UPDATE_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

import * as React from "react";
import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu";
import products from "./products.json";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getter } from "@progress/kendo-react-common";
import {
  blockUserData,
  deleteUserData,
  getUserListing,
  loginUser,
} from "../../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { UserDialogBox } from "../../../components/shared/popup/userPopup";
import { useState } from "react";
import { filterBy } from "@progress/kendo-data-query";

const DATA_ITEM_KEY = "email";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const initialFilter = {
  logic: "and",
  filters: [],
};

const App = () => {
  const { deletedStatus, userListing, blockedStatus, loading, userInfo } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [dataState, setDataState] = useState(null);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = React.useState(initialFilter);
  useEffect(() => {
    if (userInfo) {
      dispatch(getUserListing());
    } else {
      dispatch(loginUser());
    }
  }, [userInfo]);
  useEffect(() => {
    if (deletedStatus) {
      setSelectedState([]);
      dispatch(getUserListing());
    }
    if (blockedStatus) {
      setSelectedState([]);
      dispatch(getUserListing());
    }
  }, [deletedStatus, blockedStatus]);
  useEffect(() => {
    if (userListing !== null) {
      setDataState(userListing);
      //setData(dataState?.slice(skip, skip + take))
    }
  }, [userListing]);

  const [selectedState, setSelectedState] = React.useState({});
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  const handleDeleteUser = (e) => {
    e.preventDefault();
    setStatus("delete");
    setVisible(true);
  };
  const deleteUser = (e) => {
    e.preventDefault();
    setVisible(false);

    if (selectedState.length != 0) {
      let emailArr = Object.keys(selectedState);
      emailArr = emailArr.filter((item) => item != "undefined");

      let body = {
        emails: [...emailArr],
      };
      dispatch(deleteUserData(body));
    } else {
      //empty code
    }
  };

  const handleBlockUser = (e) => {
    e.preventDefault();
    setVisible(true);
    setStatus("block");
  };

  const blockUser = (e) => {
    e.preventDefault();
    setVisible(false);
    if (selectedState.length != 0) {
      let emailArr = Object.keys(selectedState);
      emailArr = emailArr.filter((item) => item != "undefined");

      let body = {
        emails: [...emailArr],
      };
      dispatch(blockUserData(body));
    } else {
      //empty code
    }
  };
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: true,
    };
    return {
      items: dataState?.length > 0 && dataState?.slice(skip, skip + take),
      total: dataState?.length > 0 && dataState.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };
  useEffect(() => {
    setState(createState(0, 10));
  }, [dataState]);

  const [state, setState] = React.useState(createState(0, 10));

  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };

  return (
    <>
      {loading ? (
        <div className="spinner-wrap">
          <div class="spinner-border"></div>
        </div>
      ) : (
        <>
          <div className="content-header">
            <div className="title jltitle">Manage Users</div>
          </div>
          <div className="content-header">
            <Button
              themeColor={"primary"}
              fillMode="outline"
              onClick={handleBlockUser}
              disabled={Object.keys(selectedState).length == 0}
            >
              Block User
            </Button>
            <Button
              icon="delete"
              className="btn-danger"
              fillMode="outline"
              onClick={handleDeleteUser}
              disabled={Object.keys(selectedState).length == 0}
            >
              Delete User
            </Button>
          </div>

          {dataState && dataState?.length > 0 && (
            <Grid
              // data={filterBy(state?.items.length > 0 && state?.items || dataState, filter)}
              data={filterBy(
                ((state?.items.length > 0 && state?.items) || dataState).map(
                  (item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  })
                ),
                filter
              )}
              {...dataState}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => {
                setFilter(e.filter);
              }}
              sortable={true}
              reorderable={true}
              onPageChange={pageChange}
              total={state.total}
              skip={state.skip}
              pageable={state.pageable}
              pageSize={state.pageSize}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
              }}
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
            >
              <Column field={SELECTED_FIELD} width="50px" />
              <Column
                field="name"
                title="Name"
                columnMenu={ColumnMenu}
                cell={(props) => (
                  <td>
                    <Link
                      className="gridlink"
                      to={{ pathname: `/user-detail/${props.dataItem.email}` }}
                    >
                      {props.dataItem.name}
                    </Link>
                  </td>
                )}
              />
              <Column
                field="email"
                title="Email"
                columnMenu={ColumnMenuCheckboxFilter}
              />
              <Column
                field="phoneNumber"
                title="Phone"
                filter={"numeric"}
                columnMenu={ColumnMenu}
              />
              <Column
                field="company"
                title="Company"
                columnMenu={ColumnMenuCheckboxFilter}
              />
              {/* <Column field="Discontinued" filter={"boolean"} title="Status" columnMenu={ColumnMenuCheckboxFilter} /> */}
            </Grid>
          )}
          {visible && (
            <UserDialogBox
              onClose={() => setVisible(false)}
              deleteUser={deleteUser}
              status={status}
              blockUser={blockUser}
            />
          )}
        </>
      )}
    </>
  );
};

export default App;

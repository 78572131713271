import {
  GET_UPLODED_LANGUAGE_ERROR,
  GET_UPLODED_LANGUAGE_INIT,
  GET_UPLODED_LANGUAGE_SUCCESS,
} from "../constants/languageConstant";

export const languageReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UPLODED_LANGUAGE_INIT:
      return { ...state, loading: true };
    case GET_UPLODED_LANGUAGE_SUCCESS:
      return { ...state, language: action.payload };
    case GET_UPLODED_LANGUAGE_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

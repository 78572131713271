export const GET_USER_LISTING_INIT = "GET_USER_LISTING_INIT";
export const GET_USER_LISTING_SUCCESS = "GET_USER_LISTING_SUCCESS";
export const GET_USER_LISTING_ERROR = "GET_USER_LISTING_ERROR";

export const DELETE_USER_INIT = "DELETE_USER_INIT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const BLOCK_USER_INIT = "BLOCK_USER_INIT";
export const BLOCK_USER_SUCCESS = "BLOCK_USER_SUCCESS";
export const BLOCK_USER_ERROR = "BLOCK_USER_ERROR";

export const GET_USER_DETAIL_INIT = "GET_USER_DETAIL_INIT";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_ERROR = "GET_USER_DETAIL_ERROR";

export const SET_USER_INFO_SUCCESS = "SET_USER_INFO_SUCCESS";
export const SET_USER_INFO_FAILURE = "SET_USER_INFO_FAILURE";

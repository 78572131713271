
import appviewModel from '../../utils/AppViewModel'
import { GET_NOTES_ERROR, GET_NOTES_INIT, GET_NOTES_SUCCESS } from '../constants/notesConstant'

export const getNotes = (data) => async (dispatch) => {
    try {
        dispatch({
             type: GET_NOTES_INIT, payload: data 
            })
        appviewModel.sendApiCall(
            '/adminNotesList',
            {},
            'POST',
            (response) => {
                console.log(response);
                // localStorage.setItem('notes', JSON.stringify(response.rows)); 
                dispatch({ type: GET_NOTES_SUCCESS, payload: response.rows })
            },
            (error) => {
                console.log(error)
                dispatch({ type: GET_NOTES_ERROR })

            }

        )
    } catch (error) {
        dispatch({
            type: GET_NOTES_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }

}



import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Table } from "react-bootstrap";
import { getNotes } from "../../../redux/actions/notesActions";
import { useDispatch, useSelector } from "react-redux";
import { find } from "@okta/okta-auth-js";
import { useParams } from "react-router-dom";
import appviewModel from "../../../utils/AppViewModel";
import { getPropertyListing } from "../../../redux/actions/propertyActions";
import * as ReactDOM from "react-dom";
import { Barcode, QRCode } from "@progress/kendo-react-barcodes";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { saveAs } from 'file-saver'
import QRcodeImg from '../../../assets/images/disable_qr_code.png';
import { QRDialogBox } from "../../../components/shared/popup/QRPopup";



const ManagePropertyDetail = () => {
  const dispatch = useDispatch()
  const [downlodedQR, setDownlodedQR] = useState(null);
  const propertListing = useSelector((state) => state.properties);
  const [AssignedStatus, setAssignedStatus] = useState(propertListing?.hasQrCode);
  const [optionsQRcode, setOptionsQRcode] = useState(["Assigned",
    "Unassigned"]);
  const [visible, setVisible] = useState(false)
  let params = useParams();
  console.log("@@@params", params);
  

  useEffect(() => {
    dispatch(getPropertyListing())
  }, []);
  useEffect(() => {
    if(propertListing !== null && AssignedStatus){
      setOptionsQRcode(["Unassigned"])
    }else{
      setOptionsQRcode(["Assigned"])

    }
  }, [propertListing])

  const propertydetails = useMemo(() => {
    const data = propertListing?.propertListing?.find((item) => item?.id === params?.id)
    return data
  }, [propertListing, params])
  console.log("@@@propertListing", propertydetails)
  const [restore, setRestore] = useState();

  //   useEffect(()=>{
  //     console.log("@@notedetails",notedetails)
  //   },[propertListing])

  const archivedUser = (e) => {
    e.preventDefault();
    if (restore) {
      let body = {
        "notes": params?.id
      }
      appviewModel.sendApiCall(
        '/adminRestoreNotes',
        body,
        'POST',
        restoreSuccess,
        restoreError
      )

    }
    else {
      let body = {
        "notes": params?.id
      }
      appviewModel.sendApiCall(
        '/adminArchiveNotes',
        body,
        'POST',
        archivedSuccess,
        archivedError
      )
    }
  }
  const archivedSuccess = (response) => {
    console.log(response);
    setRestore(true);

  }
  const archivedError = (response) => {

  }
  const restoreSuccess = (response) => {
    console.log(response);
    setRestore(false);

  }
  const restoreError = (response) => {

  }

  const options = [
    "Assigned",
    "Unassigned",
  ];
  // const [visible, setVisible] = React.useState(false);

  // const toggleDialog = () => {
  //   setVisible(!visible);
  // };

  const handleGenrate = (e) => {
    e.preventDefault();
    let body = {
      "siteID": propertydetails?.id
    }
    appviewModel.sendApiCall(
      '/adminGenerateQrCode',
      body,
      'POST',
      successHandler,
      errorHandler
    )
    handleDownload(e);
  }
  const successHandler = (response) => {
    console.log(response);
    setRestore(false);
    setAssignedStatus(true);
    dispatch(getPropertyListing())

  }
  const errorHandler = (response) => {

  }
  const handleDownload = (e) => {
    e.preventDefault();
    let body = {
      "siteID": propertydetails?.id
    }
    appviewModel.sendApiCall(
      '/adminDownloadQrCode',
      body,
      'POST',
      successHandlerQR,
      errorHandlerQR
    )

  }
  const successHandlerQR = (response) => {
    console.log(response.rows);
    const QRUrl = response.rows[0].url.slice(2);
    setDownlodedQR(QRUrl)
    setRestore(false);
    setTimeout(() => {
      downloadImage();
    }, 3000)
  }
  const errorHandlerQR = (response) => {

  }
  const downloadImage = () => {
    saveAs(downlodedQR, 'image.jpg') // Put your image url here.
  }
 const handleDropDownChange =(event)=>{
  setVisible(true);
  if(event.target.value == "Assigned"){
    setOptionsQRcode(["Unassigned"])
    setAssignedStatus(true)

  }else{
    setOptionsQRcode(["Assigned"])
    setAssignedStatus(false)

  }
 }
 const unassignedQR = () =>{
  setAssignedStatus(false);
  setVisible(false)
 }


  return (
    <>
      <div className="content-header">
        <div className="title jltitle">Notes</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Property Name : {propertydetails?.name}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Client : {propertydetails?.client}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Address : {propertydetails?.address}</div>
      </div>

      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">city : {propertydetails?.city}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">State : {propertydetails?.state}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Country : {propertydetails?.country}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">zipCode : {propertydetails?.zipCode}</div>
      </div>
      {/* <Button themeColor={"primary"} fillMode="outline" onClick={archivedUser}>
        {restore? "Restore Note": "Archive Note"}
      </Button> */}
      <br />

      {/* <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Equipment Name : {notedetails?.equipment}</div>
      </div> */}

      {/* <div className="row">
        <div className="col-md-6">

          <div className="table-card1">
            <div className="card-body">

              <div className="title jltitle">Note Content</div>
              <p className="table-content">{notedetails?.body}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="table-card1">
            <div className="card-body">
              <div className="title jltitle">Note Attachments</div>
              <p className="table-content">see inspiration document</p>
            </div>
          </div>
        </div>
      </div> */}

      <hr></hr>
      <div className="col-xs-12 col-sm-7 example-col">
        <div className="k-card-header">
          <div className="k-card-title">QR Code Status: </div>
        </div>
        <DropDownList
          style={{
            width: "300px",
          }}
          data={optionsQRcode}
          defaultValue={propertListing?.hasQrCode || AssignedStatus?"Assigned":"Unassigned"}
          disabled={!AssignedStatus}
          onChange = {handleDropDownChange}
        />
      </div>

      {/* <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        onClick={toggleDialog}
      >
        Open Dialog
      </button> */}
      {/* {visible && (
        // <Dialog title={"Please confirm"} onClose={toggleDialog}>
        //   <p
        //     style={{
        //       margin: "25px",
        //       textAlign: "center",
        //     }}
        //   >
        //     Are you sure you want to continue?
        //   </p>
        //   <DialogActionsBar>
        //     <button
        //       className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        //       onClick={toggleDialog}
        //     >
        //       No
        //     </button>
        //     <button
        //       className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        //       onClick={toggleDialog}
        //     >
        //       Yes
        //     </button>
        //   </DialogActionsBar>
        // </Dialog>
      )} */}

      <div className="k-card-deck  k-text-center">
        <div className="k-card k-text-center">
          <div className="k-card-body" disabled={false} onClick={handleGenrate}>
            {downlodedQR && AssignedStatus ? <img src={QRcodeImg} alt="disable QR code" /> : <QRCode value="https://www.telerik.com/kendo-react-ui/components/barcodes/" />}
          </div>
          <div className="k-card-header">
            <div className="k-card-title">Generate</div>
          </div>
        </div>

        <div className="k-card k-text-center">
          <div className="k-card-body" onClick={downloadImage}>
            {downlodedQR && AssignedStatus ? <QRCode value={downlodedQR || "https://www.telerik.com/kendo-react-ui/components/barcodes/"} /> : <img src={QRcodeImg} alt="disable QR code" />}

          </div>
          <div className="k-card-header">
            <div className="k-card-title">Download</div>
          </div>
        </div>
      </div>
     {visible && <QRDialogBox onClose={() => setVisible(false)} unassignedQR={unassignedQR}/>}
    </>

  );

};

export default ManagePropertyDetail;

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashbord from "./Dashboard";
import User from "./User";
import ManageProperties from "./ManageProperties";
import Notes from "./Notes";
import Language from "./Language";
import NotesDetail from "./NotesDetail";
import { LoginCallback, SecureRoute } from "@okta/okta-react";
import UserDetail from "./UserDetails";
import ManagePropertyDetail from "./ManagePropertyDetails";

function PrivatePage() {
  const allRoutes = [
    {
      module_id: 1,
      module_name: "User",
      path: "/",
      component: User,
      exact: true,
    },
    // {
    //   module_id: 1,
    //   module_name: "Dashboard",
    //   path: "/dashboard",
    //   component: User,
    //   // component: Dashbord,
    //   exact: true,
    // },
    {
      module_id: 2,
      module_name: "User",
      path: "/user",
      component: User,
      exact: true,
    },
    {
      module_id: 3,
      module_name: "Manage Properties",
      path: "/manage-properties",
      component: ManageProperties,
      exact: true,
    },
    {
      module_id: 4,
      module_name: "Notes",
      path: "/manage-notes",
      component: Notes,
      exact: true,
    },
    {
      module_id: 5,
      module_name: "Language",
      path: "/language",
      component: Language,
      exact: true,
    },
    {
      module_id: 6,
      module_name: "NotesDetail",
      path: "/note-Detail/:id",
      component: NotesDetail,
      exact: true,
    },
    {
      module_id: 7,
      module_name: "UserDetail",
      path: "/user-detail/:email?",
      component: UserDetail,
      exact: true,
    },
    {
      module_id: 7,
      module_name: "ManagePropertyDetails",
      path: "/property-detail/:id?",
      component: ManagePropertyDetail,
      exact: true,
    },
    {
      module_id: 8,
      module_name: "Logout",
      path: "/logout",
      component: User,
      exact: true,
    },
  ];

  return (
    <div className="content-wrapper">
      <Switch>
        {allRoutes.map((item, index) => {
          return (
            <SecureRoute path={item.path} exact={item.exact} key={index}>
              <item.component />
            </SecureRoute>
          );
        })}
        {/* <Redirect to="/dashboard" /> */}
        <Route path="/callback" component={LoginCallback} exact={true} />
      </Switch>
    </div>
  );
}

export default PrivatePage;

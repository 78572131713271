import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { ColumnMenu, ColumnMenuCheckboxFilter } from "../User/columnMenu";
import products from "../User/products.json";
import appviewModel from "../../../utils/AppViewModel";
import { getPropertyListing } from "../../../redux/actions/propertyActions";
import { filterBy } from "@progress/kendo-data-query";

const initialFilter = {
  logic: "and",
  filters: [
    // {
    //   field: "name",
    //   operator: "",
    //   value: "",
    // },
  ],
};

const VoilationManagement = () => {
  const dispatch = useDispatch();
  const propertyListing = useSelector((state) => state.properties);
  const [filter, setFilter] = React.useState(initialFilter);
  //const  {properties} = useSelector(({ propertyReducer }) => propertyReducer);
  const createDataState = (dataState) => {
    return {
      result: process(
        (propertyListing.propertListing || []).slice(0),
        dataState
      ),
      dataState: dataState,
    };
  };
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: true,
    };
    return {
      items: dataState?.length > 0 && dataState?.slice(skip, skip + take),
      total: dataState?.length > 0 && dataState.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings,
    };
  };

  let initialState = createDataState({
    take: 10,
    skip: 0,
  });
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(null);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState?.result);
    setDataState(updatedState?.result?.data);
  };
  // console.log("!!dataState", dataState)
  // const successHandler = (response) => {
  //   setDataState(response.rows)
  // }
  // const errorHandler = (response) => {
  //   console.log(response);
  // }
  // useEffect(() => {
  //   getData();
  // }, [])

  useEffect(() => {
    dispatch(getPropertyListing());
  }, []);

  useEffect(() => {
    if (propertyListing?.propertListing !== null) {
      setDataState(propertyListing?.propertListing);
    }
  }, [propertyListing?.propertListing]);
  // console.log("@@@propertyListing",properties?.propertyListing)

  // const getData = () => {
  //   appviewModel.sendApiCall(
  //     '/adminPropertyList',
  //     {},
  //     'POST',
  //     successHandler,
  //     errorHandler
  //   )
  // }
  const [state, setState] = React.useState(createState(0, 10));

  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };

  return (
    <>
      {(propertyListing && propertyListing?.loading) ||
      propertyListing?.loading === undefined ? (
        <div className="spinner-wrap">
          <div class="spinner-border"></div>
        </div>
      ) : (
        <>
          <div className="content-header">
            <div className="title jltitle"></div>
          </div>

          {dataState && dataState.length > 0 && (
            <Grid
              data={filterBy(
                (state?.items.length > 0 && state?.items) || dataState,
                filter
              )}
              {...dataState}
              onDataStateChange={dataStateChange}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => {
                setFilter(e.filter);
              }}
              sortable={true}
              onPageChange={pageChange}
              total={state.total}
              skip={state.skip}
              pageable={state.pageable}
              pageSize={state.pageSize}
            >
              <Column
                field="name"
                title="Property Name"
                columnMenu={ColumnMenu}
                cell={(props) => (
                  <td>
                    <Link
                      className="gridlink"
                      to={{ pathname: `/property-detail/${props.dataItem.id}` }}
                    >
                      {props.dataItem.name}
                    </Link>
                  </td>
                )}
              />
              <Column
                field="client"
                title="Client Name"
                columnMenu={ColumnMenu}
              />
              <Column field="address" title="Address" columnMenu={ColumnMenu} />
              <Column
                field="state"
                title="State"
                columnMenu={ColumnMenuCheckboxFilter}
              />
              <Column
                field="zipCode"
                title="Zipcode"
                columnMenu={ColumnMenuCheckboxFilter}
              />
              {/* <Column
        field="Discontinued"
        filter={"boolean"} title="Country"
        columnMenu={ColumnMenuCheckboxFilter}
      />
      <Column
        field="Discontinued"
        filter={"boolean"} title="QR Code Status"
        columnMenu={ColumnMenuCheckboxFilter}
      /> */}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default VoilationManagement;

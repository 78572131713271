import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getlanguageData } from "../../../redux/actions/languageActions";
import ReactFileReader from 'react-file-reader';

const Language = () => {
  const languageUrl = useSelector((state) => state.language);
  //console.log(languageUrl?.language.length > 0 && languageUrl?.language[0]?.val.split(":")[1])
  const dispatch = useDispatch();
  const languageData = [
    {
      text: "Hindi",
      id: 1,
    },
    {
      text: "English",
      id: 2,
    },
    {
      text: "French",
      id: 3,
    },
    {
      text: "German",
      id: 4,
    },
    {
      text: "Spanish",
      id: 4,
    },
  ];
  const [state, setState] = React.useState({
    value: {
      text: "English",
      id: 2,
    },
  });
  const handleChange = (event) => {
    setState({
      value: event.target.value,
    });
    let body = {
      "fileName": "english language",
      "fileType": "csv",
      "language": event.target.value.text
    }
    dispatch(getlanguageData(body));
  };
 const handleFiles = files => {
    //var reader = new FileReader();
    let formData = new FormData();
    formData.append("",files[0])
    // reader.onload = function (e) {
    //   // Use reader.result
    //   alert(reader)
    // }
    // reader.readAsText(files[0]);
    const dataUrl =  languageUrl?.language.length > 0 && languageUrl?.language[0]?.val.slice(2)
    // console.log("@@@dataUrl",dataUrl)
    // alert(dataUrl)
   
    fetch(dataUrl, {
      mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "type": "formData"
      },
      body: formData
    }).then(function (res) {
      if (res.ok) {
        alert("Perfect! ");
      } else if (res.status == 401) {
        alert("Oops! ");
      }
    }, function (e) {
      alert("Error submitting form!");
    });
  }
  return (
    <>
      <div className="content-header">
        <div className="title jltitle"></div>
      </div>

      <div className="content-header">
        <div className="title jltitle">Existing Translations:</div>
      </div>

      <div className="row">
        <div className="col-md-6">

          <div className="table-card">
            <div className="card-body">
              <div className="col-xs-12 col-sm-7 example-col">
                <DropDownList
                  style={{
                    width: "300px",
                  }}
                  data={languageData}
                  textField="text"
                  dataItemKey="id"
                  value={state.value}
                  onChange={handleChange}
                />
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>English (US)</th>
                  </tr>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
                        <button className='btn'>Upload</button>
                      </ReactFileReader>
                      {/* <input name="files" id="files" type="file" /> */}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default Language;
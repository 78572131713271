import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Table } from "react-bootstrap";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { sampleProducts } from "../sample-products";
import { useLocation, useParams } from "react-router-dom";
import appviewModel from "../../../utils/AppViewModel";
import { Popup } from "@progress/kendo-react-popup";
import { useDispatch, useSelector } from "react-redux";
import { postPropertyUpdate } from "../../../redux/actions/propertyActions";
import { UserDialogBox } from '../../../components/shared/popup/userPopup';
import { blockUserData, deleteUserData, getUserDetail } from "../../../redux/actions/userActions";

const UserDetails = () => {
  const dispatch = useDispatch();
  const propertyUpdatedStatus = useSelector((state) => state.properties);
  const { deletedStatus, userListing, blockedStatus, userDetail, loading } = useSelector((state) => state.user);

  let params = useParams();
  //console.log("@@@params", params, userDetail && userDetail[0].properties);
  const [data, setData] = React.useState([]);
  const [editID, setEditID] = React.useState(null);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(null);

  const rowClick = (event) => {
    setEditID(event.dataItem.id);
  };


  const closeEdit = (event) => {
    if (event.target === event.currentTarget) {
      setEditID(null);
    }
  };

  const addRecord = () => {
    const newRecord = {
      id: data.length + 1,
    };
    setData([newRecord, ...data]);
    setEditID(newRecord.id);
  };
  const createDataState = (dataState) => {
    return {
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 10,
    skip: 0,
  });
  const [dataState, setDataState] = React.useState(initialState.dataState);
  //const [userDetail, setUserDetail] = useState( dataState[0])

  const itemChange = (event) => {
    const inEditID = event.dataItem.id;
    const field = event.field || "";
    const newData = dataState?.properties.map((item) =>
      item.id === inEditID ? { ...item, accessValid: event.value } : item
    );
    let obj = { ...dataState, properties: [...newData] }
    setDataState(obj);
    let body = {
      "email": dataState['0']?.email,
      "property": event.dataItem.id,
      "accessValidUntil": event.value,

    }
    dispatch(postPropertyUpdate(body))
    console.log("@@@newData", newData, obj)
  };
  let location = useLocation();
  console.log("location", location, dataState)
  useEffect(() => {
    let body = {
      "email": params.email
    }
    dispatch(getUserDetail(body));
  }, [])

  useEffect(()=>{
    if(userDetail){
      let data = userDetail&&userDetail[0].properties.map((item) => {
        return { ...item, accessValid: new Date(item.accessValid.split(':')[1]) }
      })
      let obj = { ...userDetail, properties: [...data] }
      setDataState(obj);
    }

  },[userDetail])
  useEffect(() => {
    if (propertyUpdatedStatus?.propertyUpdatedStatus || deletedStatus || deletedStatus) {
      //getData()
      let body = {
        "email": params.email
      }
      dispatch(getUserDetail(body));
    }
  }, [propertyUpdatedStatus,deletedStatus,deletedStatus])

  const handleDeleteUser = (e) => {
    e.preventDefault();
    setStatus("delete");
    setVisible(true);
  }

  const deleteUser = (e) => {
    e.preventDefault();
    let body = {
      "emails": params?.email
    }
    dispatch(deleteUserData(body))
    setVisible(false);
  }
  const handleBlockUser = (e) => {
    e.preventDefault();
    setVisible(true);
    setStatus("block");
  }
  const blockUser = (e) => {
    e.preventDefault();
    let body = {
      "emails": params?.email
    }
    dispatch(blockUserData(body));
    setVisible(false);
  }
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: true,
    };
    return {
      items: dataState?.length > 0 && dataState?.slice(skip, skip + take),
      total: dataState?.length > 0 && dataState.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings
    };

  };
  useEffect(() => {
    setState(createState(0, 10));

  }, [dataState])


  const [state, setState] = React.useState(createState(0, 10));
  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };


  return (
    <>
      {loading ? <div className="spinner-wrap">
        <div class="spinner-border"></div>
      </div> :
       <>
        {dataState &&
          <div>
            <div className="content-header">
              <div className="title jltitle">User's Profile Details</div>
            </div>
            <div className="col-xs-12 col-md-4 example-col">
              <div className="title jltitle">Name : {dataState['0']?.name}</div>
            </div>
            <div className="col-xs-12 col-md-4 example-col">
              <div className="title jltitle">Email : {dataState['0']?.email}</div>
            </div>
            <div className="col-xs-12 col-md-4 example-col">
              <div className="title jltitle">Phone : {dataState['0']?.phone}</div>
            </div>

            <div className="col-xs-12 col-md-4 example-col">
              <div className="title jltitle">Company : {dataState['0']?.company} </div>
            </div>
            <div className="col-xs-12 col-md-4 example-col">
              <div className="title jltitle">Status : {dataState['0']?.company}</div>
            </div>
          </div>}

        <Button themeColor={"primary"} fillMode="outline" onClick={handleBlockUser}>
          Block User
        </Button>

        <Button themeColor={"primary"} fillMode="outline" onClick={handleDeleteUser}>
          Delete User
        </Button>

        <br />

        {dataState && dataState?.properties && dataState?.properties?.length > 0 &&
          <Grid
            style={{
              height: "420px",
            }}
            data={dataState?.properties.map((item) => ({
              ...item,
              inEdit: item.id === editID,
            }))}
            onPageChange={pageChange}
            total={state.total}
            skip={state.skip}
            pageable={state.pageable}
            pageSize={state.pageSize}
            editField="inEdit"
            onRowClick={rowClick}
            onItemChange={itemChange}
          >
            <GridToolbar>
              {/* <div onClick={closeEdit}>
              
            </div> */}
            </GridToolbar>
            <Column field="name" title="Property Name" width="150px" editable={false} />
            <Column field="name" title="Name" editable={false} />
            <Column
              field="accessValid"
              title="Access Valid till"
              editor="date"
              format="{0:d}"
            />
            <Column
              field="lastAccessed"
              title="Last Accessed"
              width="150px"
              editable={false}
            />
            <Column field="Discontinued" title="Discontinued" editable={false} />
          </Grid>
        }
        {visible && <UserDialogBox onClose={() => setVisible(false)} deleteUser={deleteUser} status={status} blockUser={blockUser} />}
        {/* <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
        Popup content.
      </Popup> */}
      </>
      }

    </>
  );

};

export default UserDetails;

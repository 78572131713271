import appviewModel from "../../utils/AppViewModel";
import {
  BLOCK_USER_ERROR,
  BLOCK_USER_INIT,
  BLOCK_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  GET_USER_DETAIL_ERROR,
  GET_USER_DETAIL_INIT,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_LISTING_ERROR,
  GET_USER_LISTING_INIT,
  GET_USER_LISTING_SUCCESS,
  SET_USER_INFO_SUCCESS,
} from "../constants/userConstant";

export const getUserListing = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_LISTING_INIT,
      payload: data,
    });
    appviewModel.sendApiCall(
      "/adminUserListing",
      {},
      "POST",
      (response) => {
        console.log(response);
        dispatch({ type: GET_USER_LISTING_SUCCESS, payload: response.rows });
      },
      (error) => {
        console.log(error);
        dispatch({ type: GET_USER_LISTING_ERROR });
      }
    );
  } catch (error) {
    dispatch({
      type: GET_USER_LISTING_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const deleteUserData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_INIT,
      payload: data,
    });
    appviewModel.sendApiCall(
      "/adminDeleteUsers",
      data,
      "POST",
      (response) => {
        console.log(response);
        dispatch({ type: DELETE_USER_SUCCESS, payload: response.rows });
      },
      (error) => {
        console.log(error);
        dispatch({ type: DELETE_USER_ERROR });
      }
    );
  } catch (error) {
    dispatch({
      type: DELETE_USER_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const blockUserData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: BLOCK_USER_INIT,
      payload: data,
    });
    appviewModel.sendApiCall(
      "/adminBlockUsers",
      data,
      "POST",
      (response) => {
        console.log(response);
        dispatch({ type: BLOCK_USER_SUCCESS, payload: response.rows });
      },
      (error) => {
        console.log(error);
        dispatch({ type: BLOCK_USER_ERROR });
      }
    );
  } catch (error) {
    dispatch({
      type: BLOCK_USER_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getUserDetail = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_DETAIL_INIT,
      payload: data,
    });
    appviewModel.sendApiCall(
      "/adminUserDetails",
      data,
      "POST",
      (response) => {
        console.log(response);
        dispatch({ type: GET_USER_DETAIL_SUCCESS, payload: response.rows });
      },
      (error) => {
        console.log(error);
        dispatch({ type: GET_USER_DETAIL_ERROR });
      }
    );
  } catch (error) {
    dispatch({
      type: GET_USER_DETAIL_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const loginUser = () => async (dispatch) => {
  try {
    let payload = {
      macAddress: "@macAddress",
      oktaUPN: JSON.parse(localStorage.getItem("okta-token-storage"))?.idToken
        ?.claims?.email,
    };
    appviewModel.sendApiCall(
      "/login",
      payload,
      "POST",
      (response) => {
        if (response && response?.rows.length) {
          localStorage.setItem(
            "okta-user-details",
            JSON.stringify(response?.rows[0])
          );
          dispatch({ type: SET_USER_INFO_SUCCESS, payload: response?.rows[0] });
        }
        // dispatch({ type: GET_USER_DETAIL_SUCCESS, payload: response.rows })
      },
      (error) => {
        // console.log(error)
        // dispatch({ type: GET_USER_DETAIL_ERROR })
      }
    );
  } catch (error) {}
};

import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Table } from "react-bootstrap";
import { getNotes } from "../../../redux/actions/notesActions";
import { useDispatch, useSelector } from "react-redux";
import { find } from "@okta/okta-auth-js";
import { useParams } from "react-router-dom";
import appviewModel from "../../../utils/AppViewModel";
import { DialogBox } from "../../../components/shared/popup/notesPopup";

const NotesDetail = () => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  let params = useParams();
  console.log("@@@params", params);
  const notes = useSelector((state) => state.notes);
  useEffect(() => {
    dispatch(getNotes())
  }, []);

  const notedetails = useMemo(() => {
    const data = notes?.notes?.find((item) => item?.id === params?.id)
    return data
  }, [notes, params])
  const [restore, setRestore] = useState(notedetails?.archived);
  const [popupData, setPopupData] = useState(notedetails?.archived ? "restore" : "archive")

  // useEffect(()=>{
  //   console.log("@@notedetails",notedetails)
  // },[notedetails])
  const showPopup = (e) => {
    e.preventDefault();
    setVisible(true);

  }
  const archivedUser = (e) => {
    e.preventDefault();
    if (restore) {
      setVisible(true)
      let body = {
        "notes": params?.id
      }
      appviewModel.sendApiCall(
        '/adminRestoreNotes',
        body,
        'POST',
        restoreSuccess,
        restoreError
      )

    }
    else {
      setVisible(true);
      let body = {
        "notes": params?.id
      }
      appviewModel.sendApiCall(
        '/adminArchiveNotes',
        body,
        'POST',
        archivedSuccess,
        archivedError
      )
    }
    setVisible(false)
  }
  const archivedSuccess = (response) => {
    console.log(response);
    setRestore(true);
    setPopupData("restore")

  }
  const archivedError = (response) => {

  }
  const restoreSuccess = (response) => {
    console.log(response);
    setRestore(false);
    setPopupData("archive")

  }
  const restoreError = (response) => {

  }

  return (
    <>
      <div className="content-header">
        <div className="title jltitle">Notes</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Auther : {notedetails?.author || "Justin"}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Email : {notedetails?.email || "Justin@gmail.com"}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Status : {notedetails?.status}</div>
      </div>

      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Property : {notedetails?.property}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Client : {notedetails?.client}</div>
      </div>
      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Publish Date and Time : {notedetails?.date}</div>
      </div>
      <Button themeColor={"primary"} fillMode="outline" onClick={showPopup}>
        {restore ? "Restore Note" : "Archive Note"}
      </Button>
      <br />

      <div className="col-xs-12 col-md-4 example-col">
        <div className="title jltitle">Equipment Name : {notedetails?.equipment}</div>
      </div>

      <div className="row">
        <div className="col-md-6">

          <div className="table-card1">
            <div className="card-body">

              <div className="title jltitle">Note Content</div>
              <p className="table-content">{notedetails?.body}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="table-card1">
            <div className="card-body">
              <div className="title jltitle">Note Attachments</div>
              <p className="table-content">see inspiration document</p>
            </div>
          </div>
        </div>
      </div>
      {visible && <DialogBox onClose={() => setVisible(false)} archivedUser={archivedUser} popupData={popupData} />}
    </>
  );
};

export default NotesDetail;

import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const QRDialogBox = ({ onClose,unassignedQR}) => {
    
    return (
        <>
            <Dialog title={"Please confirm"} onClose={onClose}>
                <p
                    style={{
                        margin: "25px",
                        textAlign: "center",
                    }}
                >
                    Are you sure you want to invalidate the previous QR
                </p>
                <DialogActionsBar>
                    <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={onClose}
                    >
                       Cancel
                    </button>
                    <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={unassignedQR}
                    >
                        Confirm
                    </button>
                </DialogActionsBar>
            </Dialog>
        </>

    );
}


import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const UserDialogBox = ({ onClose,deleteUser,status,blockUser}) => {
    return (
        <>
            <Dialog title={"Please confirm"} onClose={onClose}>
                <p
                    style={{
                        margin: "25px",
                        textAlign: "center",
                    }}
                >
                    {status === "delete" ? "Are you sure you want to permanently delete this user?" : "Are you sure you want to block this user?"}
                </p>
                <DialogActionsBar>
                    <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={onClose}
                    >
                        No
                    </button>
                    <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={status === "delete" ? deleteUser: blockUser}
                    >
                        Yes
                    </button>
                </DialogActionsBar>
            </Dialog>
        </>

    );
}


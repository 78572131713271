import appviewModel from '../../utils/AppViewModel'
import { POST_PROPERTY_UPDATE_ERROR, POST_PROPERTY_UPDATE_INIT,POST_PROPERTY_UPDATE_SUCCESS } from '../constants/propertyConstant';

export const getPropertyListing = (data) => async (dispatch) => {
    dispatch({ type: 'GET_NOTES_INIT', payload: data })
    appviewModel.sendApiCall(
        '/adminPropertyList',
        {},
        'POST',
        (response) => {
            console.log(response);
            // localStorage.setItem('notes', JSON.stringify(response.rows)); 
            dispatch({ type: 'GET_PROPERTY_SUCCESS', payload: response.rows })
        },
        (error) => { console.log(error) }
    )
    

}
export const postPropertyUpdate = (data) => async (dispatch) => {
   
    try {
        dispatch({
             type: POST_PROPERTY_UPDATE_INIT , payload: data 
            })
        appviewModel.sendApiCall(
            '/adminUserPropertyUpdate',
            data,
            'POST',
            (response) => {
                console.log(response);
                dispatch({ type: POST_PROPERTY_UPDATE_SUCCESS , payload: response.rows })
            },
            (error) => {
                console.log(error)
                dispatch({ type: POST_PROPERTY_UPDATE_ERROR })

            }

        )
    } catch (error) {
        dispatch({
            type: POST_PROPERTY_UPDATE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }

}




import {
  GET_NOTES_ERROR,
  GET_NOTES_INIT,
  GET_NOTES_SUCCESS,
} from "../constants/notesConstant";

export const notesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NOTES_INIT:
      return { ...state, loading: true };
    case GET_NOTES_SUCCESS:
      return { ...state, notes: action.payload, loading: false };
    case GET_NOTES_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};


import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getter, process } from '@progress/kendo-data-query';
import { getSelectedState, Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import { ColumnMenu, ColumnMenuCheckboxFilter } from '../User/columnMenu';
import products from '../User/products.json';
import appviewModel from "../../../utils/AppViewModel";
import { useHistory } from "react-router-dom";
import { getNotes } from "../../../redux/actions/notesActions";
import { DialogBox } from "../../../components/shared/popup/notesPopup";
import { filterBy } from "@progress/kendo-data-query";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const initialFilter = {
  logic: "and",
  filters: [
    // {
    //   field: "name",
    //   operator: "",
    //   value: "",
    // },
  ],
};

const VoilationManagement = () => {
  // const history = useHistory();
  const [visible, setVisible] = useState(false)
  const notes = useSelector((state) => state.notes);
  const [filter, setFilter] = React.useState(initialFilter);
  //console.log("loading", notes?.loading)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotes())
  }, []);
  useEffect(() => {
    if (notes?.notes !== null) {
      setDataState(notes?.notes)
    }
  }, [notes?.notes])
  const [dataState, setDataState] = useState(null);
  const [selectedState, setSelectedState] = React.useState({});


  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      console.log(newSelectedState);
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    console.log(newSelectedState);
    setSelectedState(newSelectedState);
  }, []);
  const handleArchivedUser = (e) => {
    e.preventDefault();
    setVisible(true);
  }

  const archivedUser = (e) => {
    setVisible(false);
    if (selectedState.length != 0) {
      let emailArr = Object.keys(selectedState);
      emailArr = emailArr.filter((item) => item != 'undefined')

      let body = {
        "emails": [...emailArr]
      }
      appviewModel.sendApiCall(
        '/adminArchiveNotes',
        body,
        'POST',
        archivedSuccess,
        archivedError
      )
    } else {
      //empty code
    }
  }
  const archivedSuccess = (response) => {
    console.log(response);
    setSelectedState([]);
    dispatch(getNotes())
    //getData();
  }
  const archivedError = (response) => {
  }
  const createState = (skip, take) => {
    let pagerSettings = {
      pageSizes: true,
    };
    return {
      items: dataState?.length > 0 && dataState?.slice(skip, skip + take),
      total: dataState?.length > 0 && dataState.length,
      skip: skip,
      pageSize: take,
      pageable: pagerSettings
    };

  };

  const [state, setState] = React.useState(createState(0, 10));
  useEffect(() => {
    setState(createState(0, 10));

  }, [dataState])

  const pageChange = (event) => {
    setState(createState(event.page.skip, event.page.take));
  };

  return (
    <>
      { notes && notes?.loading ? <div className="spinner-wrap">
        <div class="spinner-border"></div>
      </div> :
        <>
          <div className="content-header">
            <div className="title jltitle"></div>
          </div>
          <div className="content-header">
            <Button themeColor={"primary"} fillMode="outline" onClick={handleArchivedUser} disabled={Object.keys(selectedState).length == 0}>
              Archive
            </Button>
          </div>

          {dataState && dataState.length > 0 && <Grid
            data={filterBy((state?.items.length > 0 && state?.items || dataState).map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            })), filter)}
            {...dataState}
            filterable={true}
            filter={filter}
            onFilterChange={(e) => {
              setFilter(e.filter)
            }}
            sortable={true}
            reorderable={true}
            onPageChange={pageChange}
            total={state.total}
            skip={state.skip}
            pageable={state.pageable}
            pageSize={state.pageSize}
            //onDataStateChange={dataStateChange}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
          >
            <Column
              field={SELECTED_FIELD}
              width="50px"
            />
            <Column
              field="title"
              title="Heading"
              columnMenu={ColumnMenu}
              cell={(props) =>
                <td>
                  <Link className="gridlink" to={{ pathname: `/note-Detail/${props.dataItem.id}` }}>
                    {props.dataItem.title}
                  </Link>
                </td>}
            />
            <Column field="body" title="Auther" columnMenu={ColumnMenuCheckboxFilter} />

            <Column field="property" title="Property" columnMenu={ColumnMenu} />
            <Column
              field="client"
              title="Client"
              columnMenu={ColumnMenuCheckboxFilter}
            />
            <Column
              field="equipment"
              title="Equipment"
              columnMenu={ColumnMenuCheckboxFilter}
            />
            <Column
              field="date"
              title="Publish Date"
              columnMenu={ColumnMenuCheckboxFilter}
            />
            <Column
              field="status"
              filter={"boolean"} title="Status"
              columnMenu={ColumnMenuCheckboxFilter}
            />

          </Grid>}
          {visible && <DialogBox onClose={() => setVisible(false)} archivedUser={archivedUser} popupData="archive" />}

          {/* <ProductsLoader
        dataState={state}
        onDataReceived={dataReceived}
      /> */}

        </>
      }


    </>


  );

};

export default VoilationManagement;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/jll_logo.png";
// import {  } from '@okta/okta-react';
import { OktaAuth } from "@okta/okta-auth-js";
import { authConstants } from "../constants/auth_constants";

const Sidebar = () => {
  let oktaAuth = new OktaAuth({
    issuer: authConstants.OKTA_ISSUER,
    clientId: authConstants.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + authConstants.OKTA_REDIRECT,
  });

  console.log(window.location.origin + authConstants.OKTA_REDIRECT);

  const logout = () => {
    localStorage.removeItem("okta-user-details");
    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + "/logout",
    });
  };

  const toggleSidebar = () => {
    document.body.classList.toggle("sidebar-toggle");
  };
  const location = useLocation();
  const checkIsPathActive = (linkPaths) => {
    if (linkPaths.some((linkPath) => location.pathname.includes(linkPath))) {
      return true;
    } else return false;
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const moduleList = [
    // {
    //   module_id: 1,
    //   module_name: "Dashboard",
    //   to: "/dashboard",
    //   relatedPath: ["/dashboard"],
    //   iconClass: "fa-grip-vertical",
    // },
    {
      module_id: 2,
      module_name: "Manage Users",
      to: "/user",
      relatedPath: ["/user"],
      iconClass: "fa-user",
    },
    {
      module_id: 3,
      module_name: "Manage Properties",
      to: "/manage-properties",
      relatedPath: ["/manage-properties"],
      iconClass: "fa-house-chimney",
    },
    {
      module_id: 4,
      module_name: "Manage Notes",
      to: "/manage-notes",
      relatedPath: ["/manage-notes"],
      iconClass: "fa-book",
    },
    {
      module_id: 5,
      module_name: "Language Settings",
      to: "/language",
      relatedPath: ["/language"],
      iconClass: "fa-language",
    },
  ];

  return (
    <>
      <div className="sidebar-wrapper">
        <div className="sidebar">
          <div className="logo">
            <img src={logo} />
          </div>
          <ul className="sidenav">
            {moduleList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`${
                    checkIsPathActive(item.relatedPath) ? "active" : ""
                  }`}
                >
                  <Link to={item.to}>
                    <i className={`nav-icon fa-solid ${item.iconClass}`}></i>
                    <span className="nav-text">{item.module_name}</span>
                    <i className="nav-icon-right fa-solid fa-angle-right"></i>
                  </Link>
                </li>
              );
            })}

            <li>
              <Link to={"#"}>
                <i className={`nav-icon fa-solid fa-language`}></i>
                <span className="nav-text" onClick={logout}>
                  Logout
                </span>
                <i className="nav-icon-right fa-solid fa-angle-right"></i>
              </Link>
            </li>
          </ul>
        </div>

        <div className="toggle-btn" onClick={toggleSidebar}>
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

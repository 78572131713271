import appviewModel from '../../utils/AppViewModel'
import { GET_UPLODED_LANGUAGE_ERROR, GET_UPLODED_LANGUAGE_INIT, GET_UPLODED_LANGUAGE_SUCCESS } from "../constants/languageConstant";

export const getlanguageData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: GET_UPLODED_LANGUAGE_INIT, payload: data
        })
        appviewModel.sendApiCall(
            '/adminUploadLanguage',
            data,
            'POST',
            (response) => {
                console.log(response);
                // localStorage.setItem('notes', JSON.stringify(response.rows)); 
                dispatch({ type: GET_UPLODED_LANGUAGE_SUCCESS, payload: response.rows })
            },
            (error) => {
                console.log(error)
                dispatch({ type: GET_UPLODED_LANGUAGE_ERROR })

            }

        )
    } catch (error) {
        dispatch({
            type: GET_UPLODED_LANGUAGE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }

}
import { useReducer } from 'react'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { languageReducer } from './reducers/languageReducer'
import { notesReducer } from './reducers/notesReducer'
import { propertyReducer } from './reducers/propertyReducer'
import { userReducers } from './reducers/userReducers'

const reducer = combineReducers({
 // userLogin: userLoginReducer,
  notes : notesReducer,
  properties: propertyReducer,
  user: userReducers,
  language: languageReducer,
  userDetail: userReducers,
})



// const userInfoFromStorage = localStorage.getItem('userInfo')
//   ? JSON.parse(localStorage.getItem('userInfo'))
//   : null

//   console.log(JSON.parse(localStorage.getItem('notes')))

// const notesFromLocalStorage = localStorage.getItem('notes')?
//                               JSON.parse(localStorage.getItem('notes'))
//                                :
//                                null

const initialState = {
 // userLogin: { userInfo: userInfoFromStorage },
  notes : null
  // notesFromLocalStorage
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
)

export default store

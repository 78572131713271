import AppConstants from "./AppConstants";
import axios from "axios";

var appviewModel = {
  sendApiCall: async (url, payload, method, successHandler, ErrorHandler) => {
    method = method ? method : "POST";

    let requestBody = {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        `${AppConstants.username}:${AppConstants.password}`
      )}`,
      Accept: "application/json",
      // "IC-User-ID": "steve.bogart",
      // "IC-Access-Token":
      //   "web-SFHIsxrmfNIcFofGGx4sEFsO9d6KuW6PfflNWOV1Ifc-1c903e",
    };

    let token = localStorage.getItem("okta-user-details");

    if (token) {
      token = JSON.parse(token);
      let oktaToken = token?.accessToken.split("authToken=")[1];
      headers["IC-User-ID"] = token?.userId;
      headers["IC-Access-Token"] = oktaToken;
    }

    var callState = null;
    if (method === "POST" || method === "PUT") {
      requestBody["body"] = JSON.stringify(payload);
    }
    requestBody["headers"] = headers;
    requestBody["method"] = method;
    fetch(AppConstants.baseUrl + url, requestBody)
      .then((response) => {
        callState = response.status;
        return response.json();
      })
      .then((responsejson) => {
        if (callState === 200 || callState === 201) {
          successHandler(responsejson);
        } else {
          ErrorHandler(responsejson);
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorHandler(error);
        // alert("Something went wrong, while communicating with server.");
      });
  },
};

export default appviewModel;

import React, { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import PublicPage from "./pages/public";
import PrivatePage from "./pages/private";
import { useHistory } from "react-router-dom";

const Splash = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const userStr = sessionStorage.getItem("userToken");
  
  // sessionStorage.removeItem("userToken");
  
  const [isSignedIn, setIsSignedIn] = useState(userStr);


  return (
        <>
          <Sidebar />
          <div className="wrapper">
            <PrivatePage />
          </div>
        </>
      );
  
  // if (isSignedIn) {
  //   return (
  //     <>
  //       <Sidebar />
  //       <div className="wrapper">
  //         <PrivatePage />
  //       </div>
  //     </>
  //   );
  // }
  // return <PublicPage />;
};

export default Splash;
import {
  BLOCK_USER_ERROR,
  BLOCK_USER_INIT,
  BLOCK_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  GET_USER_DETAIL_ERROR,
  GET_USER_DETAIL_INIT,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_LISTING_ERROR,
  GET_USER_LISTING_INIT,
  GET_USER_LISTING_SUCCESS,
  SET_USER_INFO_SUCCESS,
} from "../constants/userConstant";

// export const userLoginReducer = (state = {}, action) => {
//   switch (action.type) {
//     case USER_LOGIN_REQUEST:
//       return { loading: true }
//     case USER_LOGIN_SUCCESS:
//       return { loading: false, userInfo: action.payload }
//     case USER_LOGIN_FAIL:
//       return { loading: false, error: action.payload }
//     case USER_LOGOUT:
//       return {}
//     default:
//       return state
//   }
// }
export const userReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_LISTING_INIT:
      return { ...state, loading: true };
    case GET_USER_LISTING_SUCCESS:
      return { ...state, loading: false, userListing: action.payload };
    case GET_USER_LISTING_ERROR:
      return { ...state, loading: false, error: action.payload };
    case DELETE_USER_INIT:
      return { ...state, loading: true };
    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, deletedStatus: action.payload };
    case DELETE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case BLOCK_USER_INIT:
      return { ...state, loading: true };
    case BLOCK_USER_SUCCESS:
      return { ...state, loading: false, blockedStatus: action.payload };
    case BLOCK_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case GET_USER_DETAIL_INIT:
      return { ...state, loading: true };
    case GET_USER_DETAIL_SUCCESS:
      return { ...state, loading: false, userDetail: action.payload };
    case GET_USER_DETAIL_ERROR:
      return { ...state, loading: false, error: action.payload };
    case SET_USER_INFO_SUCCESS:
      return { ...state, userInfo: action.payload };

    default:
      return state;
  }
};

import { BrowserRouter } from "react-router-dom";
import "./assets/globals.scss";
import Splash from "./Splash";
import React from "react";

import { useHistory } from "react-router-dom";
import { authConstants } from "./constants/auth_constants";

/* OKTA Login Imports*/
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
/* OKTA Login Imports*/

function App() {
  const history = useHistory();

  let oktaAuth = new OktaAuth({
    issuer: authConstants.OKTA_ISSUER,
    clientId: authConstants.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + authConstants.OKTA_REDIRECT,
    postLogoutRedirectUri: authConstants.OKTA_LOGOUT_REDIRECT,
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl("/user", window.location.origin));
  };

  return (
    <div className="main">
      {/* <BrowserRouter> */}
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Splash />
      </Security>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
